.courseNavContainer {
    display: inline-block;
    width: 70%;
    margin: auto;
    margin-top: 1.5rem;
}

#course_button {
    background: none;
    border: none;
    border-radius: 0.5rem;
    height: 2.8rem;
    font-size: 1em;
    color: #5D3DC9;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

#course_button_active {
    background: none;
    border: none;
    border-radius: 0.5rem;
    height: 2.8rem;
    font-size: 1em;
    background-color: #5D3DC9;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: initial;
}

#course_button:hover {
    background-color: #E7EFF7;
}