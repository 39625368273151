#container {
    text-align: center;
    margin-top: 1.25rem;
}

.tableViewContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    align-items: top;
    text-align: center;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 2rem;
}

.GradeDisplay {
    margin-top: 1rem;
    line-height: 3.2rem;
    color: #36DEA3;
    font-size: 3em;
    font-weight: 700;
    border-bottom: solid 0.5rem #36DEA3;
    display: inline-block;
}

.gradeDisplayContainer {
    display: flex;
    align-items: center;
    margin: auto;
    width: 30%;
}

.gradeDisplayCol {
    flex: 1;
}

.editClassContainer {
    display: flex;
    align-self: left;
    margin-bottom: 1.2rem;
}