.assignment-input {
    border: none;
    font-size: 1em;
    width: 90%;
    padding: 0.25rem 1rem 0.25rem 1rem;
    text-align: center;
    transition: all 0.05s ease 0s;
}

.points-earned-input {
    text-align: center;
    border: none;
    width: 50%;
    padding: 0.25rem 0rem 0.25rem 0rem;
    font-size: 1em;
    transition: all 0.2s ease 0s;
}

.points-possible-input {
    text-align: center;
    border: none;
    width: 50%;
    padding: 0.25rem 0rem 0.25rem 0rem;
    font-size: 1em;
    transition: all 0.2s ease 0s;
}

tr {
    border-bottom: 0.1rem solid #DFEAF5;
}

.data {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.assignment-input:hover, .points-earned-input:hover, .points-possible-input:hover {
    background-color: #E7EFF7;
}