.TableView {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.3rem #CCCFD1;
    padding: 0rem 2rem 1rem 2rem;
    margin-bottom: 3.9rem;
    width: 40rem;
    transition: all 0.2s ease-in-out;
    height: fit-content;
}

.weightTable {
    width: 100%;
    table-layout: fixed;
}

#table-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0vw 1rem 0vw;

}

#table-header {
    margin: none;
}

.weightGroupName {
    font-weight: 500;
    border: none;
    font-size: 1.25em;
    padding-left: 0.5rem;
}

.weightGroupName:hover {
    background-color: #E7EFF7;
}

#delete_button {
    background: none;
    border: none;
    cursor: pointer;
    color: #718CA5;
    transition: all 0.2s ease 0s;
}

#delete_button:hover {
    background-color: #DE3643;
    color: white;
    border-radius: 100%;
    
}

table {
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 1em;
}

th {
    font-weight: 100;
}

tr {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#header-row {
    border: none;
}

#assignment-name-header {
    text-align: center;
}

#grade-header {
    text-align: center;
}

.assignment-field {
    width: 30%;
}

.points-earned-field {
    width: 25%;
}

.points-possible-field {
    width: 25%;
}

.grade-field {
    width: 18%;
}

.deleteAssignmentField {
    width: 2%;
}

.add-new-assignment {
    background: none;
    border: none;
    color: #36DEA3;
    font-size: 1.1em;
    transition: all 0.2s ease 0s;
}

.add-new-assignment-text {
    font-size: 1.15em;
    transition: all 0.2s ease 0s;
}

.add-new-assignment-text:hover {
    color:#2fe9a8;
}

#table-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#weight-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#table-footer {
    margin-right: 1.3rem;
    font-weight: normal;
}

input {
    font-family: Poppins;
    border-color: #99B0C6;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.2rem;
    color: #718CA5;
}

.new-assignment-input {
    padding: 0.25rem 0rem 0.25rem 0rem;
    text-align: center;
    width: 100%;
}

.new-points-earned-input {
    width: 50%;
    text-align: center;
    padding: 0.25rem 0rem 0.25rem 0rem;
}

.new-points-possible-input {
    padding: 0.25rem 0rem 0.25rem 0rem;
    text-align: center;
    width: 50%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.weight-input {
    width: 3ch;
    font-size: 1.25em;
    padding: 0.2rem 0.2rem 0rem 0.6rem;
}

#weight-grade {
    font-weight: 500;
    background-color: #DED136;
    color: white;
    padding: 0.5rem 1.75rem 0.5rem 1.75rem;
    border-radius: 0.6rem;
}