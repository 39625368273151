.modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .modal-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em 1.5em 1.5em 1.5em;
    background-color: #F4F7FA;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 0.4vw;

  }
  
  @media screen and (min-width: 500px) {
    /* Center the Modal! */
    .modal-area {
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
      max-width: 30em;
      max-height: calc(100% - 1em);
    }
  }
  
  .modal-body {
    padding-top: 0.25em;
  }
  
  ._hide-visual {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  
  .scroll-lock {
    overflow: hidden;
  }