@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
html {
    font-size: 16px;
}

body {
    background-color: #F4F7FA;
    color: #65809B;
    margin: 0;
    font-family: Poppins; 
}

h1 {
    font-size: 2.5rem;
}

input[type=text]:focus {
    border-color: #29A0FF;

}

@media only screen and (max-width: 850px) {
    html {
        font-size: 0.75em;
    }
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 0.5em;
    }
}
.App {
    margin: 0;
    text-align: center;
    margin-top:18vh;
    width: 100%;
}
.blurb {
    background-color: #FFFFFF;
    box-shadow: 0.3rem 0.3rem #CCCFD1;
    display: inline-block;
    border-radius: 1rem;
    width: 45rem;
}

.blurb-text {
    font-size: 1.25em;
    line-height: 2.2rem;
    text-align: center;
}

.noClassBlurb {
    width: 40rem;
    padding: 0.75rem 2rem 0.75rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.signInBlurb {
    padding: 2.5rem;
}

.signInBlurbText {
    margin: 0 0 2rem 0;
}

@media only screen and (max-width: 600px) {
    .blurb-text {
        font-size: 1.5em;
        line-height: 2.5rem;
    }
}

@media only screen and (max-width: 450px) {
    .blurb {
        padding: 1rem 5rem 1rem 5rem;
        width: 25rem;
        border-radius: 2rem;
        margin-bottom: 8rem;
    }

    .blurbHeader {
        font-size: 3em;
    }

    .blurb-text {
        font-size: 2.25em;
        line-height: 3rem;
    }

    .signInBlurbText {
        margin-top: 3rem;
    }
}
button {
    font: inherit;
    cursor: pointer;
    outline: none;
    color: #FFFFFF;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

#sign_in {
    background-color: #36DEA3;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5em 3em 0.5em 3em;
    font-size: 1.25rem;
    font-weight: 500;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

#sign_in:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

#sign_out {
    background-color: #DE3643;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0.5rem;
    padding: 0.5em 3em 0.5em 3em;
    font-size: 0.9em;
}

#sign_out:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.add_class_weight {
    background-color: #29A0FF;
    border: none;
    border-radius: 0.5rem;
    padding: 0.7rem 1.8rem 0.7rem 1.8rem;
    font-size: 0.9em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.add_class_weight:hover {
    -webkit-transform: translateY(-0.1rem);
            transform: translateY(-0.1rem);
}

.add_class {
    background-color: #36DEA3;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0.5rem;
    padding: 0.5em 3em 0.5em 3em;
    font-size: 0.9em;
    margin-right: 2rem;
}

.add_class:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.delete_button {
    background: none;
    border: none;
    cursor: pointer;
    color: #718CA5;
    border-radius: 0.3rem;
    font-size: 1.1em;   
    transition: all 0.2s ease 0s;
}

.delete_button:hover {
    background-color: #DE3643;
    color: white;
}

#exit_button {
    padding: 0.2rem 0.8rem 0.2rem 0.8rem;
    border-radius: 100%;
    font-size: 1.1em;
    transition: all 0.2s ease 0s;
}

#exit_button:hover {
    background-color: #E7EFF7;
}

.deleteWeightButton {
    background-color: #DE3643;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0.3vw;
    padding: 0.25vw 0.8vw 0.25vw 0.8vw;
    transition: all 0.2s ease 0s;
}

.deleteWeightButton:hover {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}

.deleteAssignmentButton {
    background: none;
    border: none;
    color: #65809B;
    transition: all 0.2s ease 0s;
}

.deleteAssignmentButton:hover {
    color: #DE3643;
}

.editClassButton {
    background:none;
    border: none;
    color: #bbccdd;
    font-size: 1.75em;
    transition: all 0.5s ease 0s;
    padding: 0;
    margin-left: 0.5rem;
}

.editClassButton:hover {
    color:#65809B;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.finishButton {
    background-color: #36DEA3;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0.3rem;
    font-size: 1.1em;
    padding: 0.5rem 2rem 0.5rem 2rem;
}

.finishButton:hover {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}



@media only screen and (max-width: 1090px) {
    .add_class {
        padding: 0.6rem 0.95rem 0.6rem 0.95rem;
        border-radius: 100%;
    }

    #sign_out {
        padding: 0.6rem 0.95rem 0.6rem 0.95rem;
        border-radius: 100%;
    }
}

@media only screen and (max-width: 600px) {
    #sign_in {
        font-size: 1.75em;
        border-radius: 1rem;
    }
}

@media only screen and (max-width: 450px) {
    #sign_in {
        font-size: 2.2em;
        border-radius: 1rem;
        display: block;
        margin: auto;
    }
}
.Nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 1.5rem auto auto auto;
}

.navHeader {
    font-size: 2em;
    margin: 0;
}

#button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* Modal Content */

#modal_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    margin-bottom: 0.8rem;
}

#form_title {
    margin-top: 0;
    margin-bottom: 0;
}

#exit_button {
    color: #718CA5;
    outline: none;
    border: none;
    cursor: pointer;
    background: none;
    font-size: 1.5em;
}

#class_form {
    display: block;
}

#input_title {
    font-size: 1em;
}

#class_name_input {
    margin-top: 0.5rem;
    width: 100%;
    font-size: 1.3em;
    padding: 0.25rem 0.25rem 0.25rem 0.75rem;
    box-sizing: border-box;
    border-color: #99B0C6;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    outline: none;
    font-family: Poppins;
    color: #65809B;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #CCDDED;
    opacity: 1; /* Firefox */
  }

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #CCDDED;
    opacity: 1; /* Firefox */
  }

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #CCDDED;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #CCDDED;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #CCDDED;
  }

  #submit {
      float: right;
      margin-top: 1rem;
      color: white;
      font-family: Poppins;
      font-weight: 500;
  }
.modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .modal-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1em 1.5em 1.5em 1.5em;
    background-color: #F4F7FA;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 0.4vw;

  }
  
  @media screen and (min-width: 500px) {
    /* Center the Modal! */
    .modal-area {
      left: 50%;
      top: 50%;
      height: auto;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      max-width: 30em;
      max-height: calc(100% - 1em);
    }
  }
  
  .modal-body {
    padding-top: 0.25em;
  }
  
  ._hide-visual {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  
  .scroll-lock {
    overflow: hidden;
  }
.courseNavContainer {
    display: inline-block;
    width: 70%;
    margin: auto;
    margin-top: 1.5rem;
}

#course_button {
    background: none;
    border: none;
    border-radius: 0.5rem;
    height: 2.8rem;
    font-size: 1em;
    color: #5D3DC9;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

#course_button_active {
    background: none;
    border: none;
    border-radius: 0.5rem;
    height: 2.8rem;
    font-size: 1em;
    background-color: #5D3DC9;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: bold;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: auto;
    cursor: initial;
}

#course_button:hover {
    background-color: #E7EFF7;
}
.assignment-input {
    border: none;
    font-size: 1em;
    width: 90%;
    padding: 0.25rem 1rem 0.25rem 1rem;
    text-align: center;
    transition: all 0.05s ease 0s;
}

.points-earned-input {
    text-align: center;
    border: none;
    width: 50%;
    padding: 0.25rem 0rem 0.25rem 0rem;
    font-size: 1em;
    transition: all 0.2s ease 0s;
}

.points-possible-input {
    text-align: center;
    border: none;
    width: 50%;
    padding: 0.25rem 0rem 0.25rem 0rem;
    font-size: 1em;
    transition: all 0.2s ease 0s;
}

tr {
    border-bottom: 0.1rem solid #DFEAF5;
}

.data {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.assignment-input:hover, .points-earned-input:hover, .points-possible-input:hover {
    background-color: #E7EFF7;
}
.TableView {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.3rem #CCCFD1;
    padding: 0rem 2rem 1rem 2rem;
    margin-bottom: 3.9rem;
    width: 40rem;
    transition: all 0.2s ease-in-out;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.weightTable {
    width: 100%;
    table-layout: fixed;
}

#table-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0vw 1rem 0vw;

}

#table-header {
    margin: none;
}

.weightGroupName {
    font-weight: 500;
    border: none;
    font-size: 1.25em;
    padding-left: 0.5rem;
}

.weightGroupName:hover {
    background-color: #E7EFF7;
}

#delete_button {
    background: none;
    border: none;
    cursor: pointer;
    color: #718CA5;
    transition: all 0.2s ease 0s;
}

#delete_button:hover {
    background-color: #DE3643;
    color: white;
    border-radius: 100%;
    
}

table {
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 1em;
}

th {
    font-weight: 100;
}

tr {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#header-row {
    border: none;
}

#assignment-name-header {
    text-align: center;
}

#grade-header {
    text-align: center;
}

.assignment-field {
    width: 30%;
}

.points-earned-field {
    width: 25%;
}

.points-possible-field {
    width: 25%;
}

.grade-field {
    width: 18%;
}

.deleteAssignmentField {
    width: 2%;
}

.add-new-assignment {
    background: none;
    border: none;
    color: #36DEA3;
    font-size: 1.1em;
    transition: all 0.2s ease 0s;
}

.add-new-assignment-text {
    font-size: 1.15em;
    transition: all 0.2s ease 0s;
}

.add-new-assignment-text:hover {
    color:#2fe9a8;
}

#table-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#weight-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#table-footer {
    margin-right: 1.3rem;
    font-weight: normal;
}

input {
    font-family: Poppins;
    border-color: #99B0C6;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.2rem;
    color: #718CA5;
}

.new-assignment-input {
    padding: 0.25rem 0rem 0.25rem 0rem;
    text-align: center;
    width: 100%;
}

.new-points-earned-input {
    width: 50%;
    text-align: center;
    padding: 0.25rem 0rem 0.25rem 0rem;
}

.new-points-possible-input {
    padding: 0.25rem 0rem 0.25rem 0rem;
    text-align: center;
    width: 50%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.weight-input {
    width: 3ch;
    font-size: 1.25em;
    padding: 0.2rem 0.2rem 0rem 0.6rem;
}

#weight-grade {
    font-weight: 500;
    background-color: #DED136;
    color: white;
    padding: 0.5rem 1.75rem 0.5rem 1.75rem;
    border-radius: 0.6rem;
}
.modalError {
    background-color: #DE3643;
    color: white;
    padding: 1.25vw;
    position: absolute;
    left: 0;
    top: 0;
}
.errorModal-enter {
    opacity: 0.01;
  }
  
  .errorModal-enter.errorModal-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  .errorModal-leave {
    opacity: 1;
  }
  
  .errorModal-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
.submitContainer {
    margin-top: 1.5vw;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

#container {
    text-align: center;
    margin-top: 1.25rem;
}

.tableViewContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    align-items: top;
    text-align: center;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 2rem;
}

.GradeDisplay {
    margin-top: 1rem;
    line-height: 3.2rem;
    color: #36DEA3;
    font-size: 3em;
    font-weight: 700;
    border-bottom: solid 0.5rem #36DEA3;
    display: inline-block;
}

.gradeDisplayContainer {
    display: flex;
    align-items: center;
    margin: auto;
    width: 30%;
}

.gradeDisplayCol {
    flex: 1 1;
}

.editClassContainer {
    display: flex;
    align-self: left;
    margin-bottom: 1.2rem;
}
