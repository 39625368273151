button {
    font: inherit;
    cursor: pointer;
    outline: none;
    color: #FFFFFF;
    transition: transform 0.2s ease-in-out;
}

#sign_in {
    background-color: #36DEA3;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5em 3em 0.5em 3em;
    font-size: 1.25rem;
    font-weight: 500;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

#sign_in:hover {
    transform: translateY(-2px);
}

#sign_out {
    background-color: #DE3643;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0.5rem;
    padding: 0.5em 3em 0.5em 3em;
    font-size: 0.9em;
}

#sign_out:hover {
    transform: translateY(-2px);
}

.add_class_weight {
    background-color: #29A0FF;
    border: none;
    border-radius: 0.5rem;
    padding: 0.7rem 1.8rem 0.7rem 1.8rem;
    font-size: 0.9em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.add_class_weight:hover {
    transform: translateY(-0.1rem);
}

.add_class {
    background-color: #36DEA3;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0.5rem;
    padding: 0.5em 3em 0.5em 3em;
    font-size: 0.9em;
    margin-right: 2rem;
}

.add_class:hover {
    transform: translateY(-2px);
}

.delete_button {
    background: none;
    border: none;
    cursor: pointer;
    color: #718CA5;
    border-radius: 0.3rem;
    font-size: 1.1em;   
    transition: all 0.2s ease 0s;
}

.delete_button:hover {
    background-color: #DE3643;
    color: white;
}

#exit_button {
    padding: 0.2rem 0.8rem 0.2rem 0.8rem;
    border-radius: 100%;
    font-size: 1.1em;
    transition: all 0.2s ease 0s;
}

#exit_button:hover {
    background-color: #E7EFF7;
}

.deleteWeightButton {
    background-color: #DE3643;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0.3vw;
    padding: 0.25vw 0.8vw 0.25vw 0.8vw;
    transition: all 0.2s ease 0s;
}

.deleteWeightButton:hover {
    transform: translateY(-1px);
}

.deleteAssignmentButton {
    background: none;
    border: none;
    color: #65809B;
    transition: all 0.2s ease 0s;
}

.deleteAssignmentButton:hover {
    color: #DE3643;
}

.editClassButton {
    background:none;
    border: none;
    color: #bbccdd;
    font-size: 1.75em;
    transition: all 0.5s ease 0s;
    padding: 0;
    margin-left: 0.5rem;
}

.editClassButton:hover {
    color:#65809B;
    transform: rotate(-45deg);
}

.finishButton {
    background-color: #36DEA3;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0.3rem;
    font-size: 1.1em;
    padding: 0.5rem 2rem 0.5rem 2rem;
}

.finishButton:hover {
    transform: translateY(-1px);
}



@media only screen and (max-width: 1090px) {
    .add_class {
        padding: 0.6rem 0.95rem 0.6rem 0.95rem;
        border-radius: 100%;
    }

    #sign_out {
        padding: 0.6rem 0.95rem 0.6rem 0.95rem;
        border-radius: 100%;
    }
}

@media only screen and (max-width: 600px) {
    #sign_in {
        font-size: 1.75em;
        border-radius: 1rem;
    }
}

@media only screen and (max-width: 450px) {
    #sign_in {
        font-size: 2.2em;
        border-radius: 1rem;
        display: block;
        margin: auto;
    }
}