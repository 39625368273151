.blurb {
    background-color: #FFFFFF;
    box-shadow: 0.3rem 0.3rem #CCCFD1;
    display: inline-block;
    border-radius: 1rem;
    width: 45rem;
}

.blurb-text {
    font-size: 1.25em;
    line-height: 2.2rem;
    text-align: center;
}

.noClassBlurb {
    width: 40rem;
    padding: 0.75rem 2rem 0.75rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.signInBlurb {
    padding: 2.5rem;
}

.signInBlurbText {
    margin: 0 0 2rem 0;
}

@media only screen and (max-width: 600px) {
    .blurb-text {
        font-size: 1.5em;
        line-height: 2.5rem;
    }
}

@media only screen and (max-width: 450px) {
    .blurb {
        padding: 1rem 5rem 1rem 5rem;
        width: 25rem;
        border-radius: 2rem;
        margin-bottom: 8rem;
    }

    .blurbHeader {
        font-size: 3em;
    }

    .blurb-text {
        font-size: 2.25em;
        line-height: 3rem;
    }

    .signInBlurbText {
        margin-top: 3rem;
    }
}