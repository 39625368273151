@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

html {
    font-size: 16px;
}

body {
    background-color: #F4F7FA;
    color: #65809B;
    margin: 0;
    font-family: Poppins; 
}

h1 {
    font-size: 2.5rem;
}

input[type=text]:focus {
    border-color: #29A0FF;

}

@media only screen and (max-width: 850px) {
    html {
        font-size: 0.75em;
    }
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 0.5em;
    }
}